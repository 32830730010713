/*  */
import $ from "jquery";
import { createGuid } from "./general_functions";

export const set_accordian = (content = false) => {


    $("body").off("click","button.accordian-titlebutton");
    $("body").on("click", `button.accordian-titlebutton`, function() {

        // console.log("click");

        $(this).toggleClass("accordian-titlebutton--open");
        $($(this).data("target")).toggleClass("accordian-content--open");
        $(this).attr("aria-expanded", ($(this).hasClass("accordian-titlebutton--open") ? true : false));
        

    });

    if(content){
        let c = $(`<div class="temp-wrapper">${content}</div>`);
    
        c.find(".accordian").each(function(nr, obj) {
            
            let id = createGuid();
    
    
            const that = $(this),
                titletext = $(this).find(">.accordian-title").text(), //TODO fix h3
                title = $(this).find(">.accordian-title"),
                content = $(this).find(".accordian-content"),
                titleid = "accordian-boxheader-" + id,
                contentid = "accordian-boxcontent-" + id,
                boxid = "box-" + id;
                
                // console.log(titletext, $(this).html());
    
            title.addClass("accordian-title");
    
            $(this).attr("id", boxid);
            title.attr("id", titleid).removeAttr("onblur").removeAttr("onclick");
            content.attr("id", contentid).removeAttr("onblur").removeAttr("onclick");;
            
    
            title.html(`
                        <button class="accordian-titlebutton" type="button" data-toggle="collapse" data-target="#${contentid}" aria-expanded="false" aria-controls="${contentid}">
                          ${titletext}
                        </button>`);
    
            content.attr({ "aria-labelledby": titleid, "data-parent": boxid });
    
    
        });
        
        return c.html();
    }

}
