import $ from "jquery";
import { getTransportpages } from "../../scripts/tools/getcontent";
import { createGuid, checkNested } from "../../scripts/general_functions";

import tinysort from "tinysort";

export default (categories) => {

    let me = page.replace("nav/", "");

    let me_cat = getSpecificCategory(categories, { item: me })

    if (me.match("/")) {
        me = split("/");
    }
    else {
        me.split("/");
    }

    let breadcrumbs = `
        
        <div class="bc">
            
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <div class="bc__container">
                            <a href="/${site}" class="bc__item bc__item-link">Forside</a>
                            <span class="bc__item">${me_cat.previewItem}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    `;

    let localCategories = false;

    if (me_cat) {
        localCategories = me_cat;
    }

    navigation(localCategories, false, "#navitems");

    // console.log("[me_cat]",me_cat);

    $("#content").append(`
        
            ${breadcrumbs}
        
        
            <div class="container">
                <div class="row ">
                    <div class="col">
                    
                        
                        <h2 class="article__header__title ">${me_cat.previewItem}</h2>
                        
                        ${me_cat.description ? `
                        <p class="article__header__intro">${me_cat.description}</p>`
                        : ""}
                    
                        <div class="row mt-5" id="navitems">
                        
                            
                        </div>
                    
                    </div>
                </div>
            </div>
        `);


}




export const getSpecificCategory = function(rest, keyObj) {
    var p, key, val, tRet;
    for (p in keyObj) {
        if (keyObj.hasOwnProperty(p)) {
            key = p;
            val = keyObj[p];
        }
    }
    // console.log(p, rest);

    for (p in rest) {

        if (p == key) {
            let urlrestp = urlify(rest[p]);
            urlrestp = urlrestp.replace(/\?/gmi, "");
            // console.log("val",this[p],urlify(val));
            if (urlify(rest[p]) == urlify(val)) {
                return rest;
            }
        }
        else if (rest[p] instanceof Object) {
            if (rest.hasOwnProperty(p)) {
                tRet = getSpecificCategory(rest[p], keyObj);
                if (tRet) { return tRet; }
            }
        }
    }

    return false;
};



export const navItemHtml = (navitem) => {
    // console.log("navitem", navitem);

    let modified = navitem.modified || 0;
    
    let url = navitem.externalurl;
    if(navitem.externalurl === undefined || navitem.externalurl === "")  
    url = `/${site}/${navitem.type}/${navitem.url}`;
    
    return `
        <div class="col-sm-4 navitemhtml cpnav__link__mb" data-sortby="${modified}">
            <a href="${url}" class="cpnav__link h-100 ${navitem.desc.length < 1 ? "" : "cpnav__link__norightpadding"}">
                <strong class="cpnav__link__header">${navitem.title}</strong>
                <div class="cpnav__link__intro">${navitem.desc}</div>
                ${navitem.desc.length < 1 ? `<span class="budlight-arrow-right cpnav__link__arrow"></span>` : ""}
            </a>
        </div>`;
}



export const navigation = (categories, articles, appendTo) => {

    // console.log("[navigation]", categories, articles, appendTo);


    let promises = [];
    let category = categories;

    // console.log("[typeof categories]", typeof categories, category, categories.length);

    if (typeof categories.length === "undefined") {
        if (categories.children) {
            categories = categories.children;
        }
        else {
            categories = false;
        }
    }

    // console.log("category", category, categories);

    if (categories) {

        categories.forEach(navitem => {

            promises.push(new Promise((resolve, reject) => {


                let catHtml = navItemHtml({
                    type: "nav",
                    externalurl: navitem.externallink,
                    url: urlify(navitem.item),
                    title: navitem.previewItem,
                    desc: navitem.description
                })


                getTransportpages(navitem.categoryId, items => {

                    let catnavs = [];

                    // console.log("items", items);

                    if (items.length > 0) {
                        items.forEach(transportPage => {

                            let desc = "";
                            if (checkNested(transportPage, "metaDynamic", "Intro")) {
                                desc = transportPage.metaDynamic.Intro
                            }
                            if(transportPage.isVisibleInNavigation) {
                                let catnavHtml = navItemHtml({
                                    modified: transportPage.modified,
                                    type: "navside",
                                    title: transportPage.title,
                                    url: transportPage.nameInUrl, //+ "?categoryid=" + navitem.categoryId,
                                    desc: desc
                                });
                                catnavs.push(catnavHtml);
                            }

                        })

                        resolve(catnavs.join(""));

                    }
                    else {
                        // catnavs.push(catHtml);
                        resolve(catHtml);
                    }

                });



            })); // promise



        }); // foreach promise

    }


    if (articles) {
        articles.forEach(navitem => {

            promises.push(new Promise((resolve, reject) => {
                let type = "artikkel";
                if (navitem.pageTypeId === "mal_enhetsmal")
                    type = "enhet";

                let navsHtml = navItemHtml({
                    modified: navitem.modified,
                    type: type,
                    url: navitem.nameInUrl,
                    title: navitem.title,
                    desc: navitem.intro
                });

                resolve(navsHtml);
            }));

        });
    }
    else {

        let senddata = {
            "categoryId": category.categoryId,
            "subscriptionId": config.clientId
        };

        promises.push(new Promise((resolve, reject) => {

            $.ajax({
                type: "POST",
                url: window.apiUrl + "/api/PublicContentApi/getbycategoryidasstring",
                contentType: "application/json-patch+json",
                data: JSON.stringify(senddata)
            }).done(function(catarticles) {

                let articleHtml = []

                catarticles.forEach(navitem => {

                    let navhtml = "";

                    let type = "artikkel";
                    if (navitem.pageTypeId === "mal_enhetsmal")
                        type = "enhet";


                    navhtml = navItemHtml({
                        url: navitem.nameInUrl,
                        type: type,
                        title: navitem.title,
                        desc: navitem.intro
                    });

                    articleHtml.push(navhtml);

                });

                let compiled__article__html = articleHtml.join("");

                resolve(compiled__article__html);

            });

        }))


    }


    Promise.all(promises).then(elm => {

        // console.log(appendTo, elm);

        $(appendTo).append(elm);

        if ($(".navitemhtml").lengt > 0) {

            tinysort(".navitemhtml", { data: "sortby" })
        }

    })


}
