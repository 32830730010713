import $ from "jquery";

export const getcontent = (articledef, cb) => {

    // let suburl = getPageUrl().contentid;

    let articleCall = {
        type: "get",
        url: window.apiUrl + "/api/PublicContentApi/find/" + articledef + "/" + config.clientId
    }

    if (getUrlAttr("preview") == "true") {
        articleCall.url = window.apiUrl + "/api/ContentApi/find/" + articledef + "/preview";
        articleCall.headers = { "Authorization": "Bearer " + sessionStorage.getItem("access_token") }
    }


    if (!articledef.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {

        articleCall.url = `${window.apiUrl}/api/PublicContentApi/findbynameinurl/${articledef}/${config.clientId}`;
    }

    $.ajax(articleCall).done(function(data) {

        let urlPath = "";
        
        urlPath = data.shortNameInUrl || data.nameInUrl || data.contentId
        
        urlPath = urlPath + window.location.search;

        if (getUrlAttr("preview") != "true") {
            if (data.pageTypeId != "mal_blokk") {
                window.history.pushState({ path: urlPath, pageTitle: data.title + " - " + config.name },
                    "",
                    urlPath
                );
            }
        }


        cb(data);

    });
}

export const getList = (obj, callback) => {
    
    obj = Object.assign({
        type : "mal_artikkel",
        category : false,
        from : 0,
        num : 1000,
        sort : "Modified"
    }, obj);
    
    // console.log(obj);
    
    return $.getJSON(window.apiUrl + `/api/PublicContentApi/getcontentlistdescending/${config.clientId}/${obj.type}/${obj.num}/${obj.from}/${obj.sort}`).done((result) => {

        console.log("result", result);

        callback(result);

    });
}
export const getTransportpages = (categoryId, cb) => {
    
    let cbItems = [];
    
    getArticlesByCat(categoryId, catarticles => {
        
        catarticles.forEach(catarticleItem => {

            if (catarticleItem.pageTypeId === "mal_dynamisk") {
                
                // cb(catarticleItem);
                
                if(catarticleItem.metaObject){
                    let meta = JSON.parse(decodeURIComponent(catarticleItem.metaObject));
                    // ;
                    catarticleItem.metaParsed = meta;
                    
                    
                    if(meta.currentDynamiskMalContent){
                            catarticleItem.metaDynamic = {};
                        meta.currentDynamiskMalContent.forEach(metaitem => {
                            if(metaitem){
                                // console.log(metaitem);
                                catarticleItem.metaDynamic[metaitem.title] = metaitem.value;
                            }
                        });
                    }
                    
                    // console.log(catarticleItem);
                    
                    if(meta.id === "1bb71f41-4ee6-4394-b78f-3202e2f5eeda"|| meta.contentTypeId === "1bb71f41-4ee6-4394-b78f-3202e2f5eeda"){
                        cbItems.push(catarticleItem);
                    }
                }
                
            }

        });
        
        cb(cbItems);
        
    } );
    
};


export const getArticlesByCat = (categoryId, cb) => {

    let query = JSON.stringify({
        "categoryId": categoryId,
        "subscriptionId": config.clientId
    });

    $.ajax({
        type: "POST",
        url: window.apiUrl + "/api/PublicContentApi/getbycategoryidasstring",
        contentType: "application/json-patch+json",
        data: query
    }).done(function(catarticles) {
        
        // if(catarticles.metaObject){
        //     catarticles.metaParsed = JSON.parse(decodeURIComponent(catarticles.metaObject));
        //     if(catarticles.metaParsed.currentDynamiskMalContent && catarticles.metaParsed.currentDynamiskMalContent.length > 0){
        //         catarticles.metaDynamic = {};
        //         catarticles.metaParsed.currentDynamiskMalContent.forEach(cmc => {
        //             catarticles[cmc.title] = cmc.value;
        //         })
        //     }
        // }
        
        // console.log(catarticles);
        
        cb(catarticles);

    });

}
