import { compile_content } from "../../scripts/tools/compile_content.js";

export default () => {


    fetch(window.apiUrl + "/api/PublicContentApi/find/" + aimMeta.frontpage).then(response => response.json()).then(data => {

        // console.log("data frontpage",data);

        let html = decodeURIComponent(data.contentObject);

        
        let meta = JSON.parse(decodeURIComponent(data.metaObject));

        // console.log(data,meta)
        let title = data.title;
        
        meta.currentDynamiskMalContent.filter(elm => {
            if(elm.localId === "a1452d97-a2ef-42c8-b4e6-de1ee2940ee7"){
                return title = elm.value;
            }
        })

        html = `
        
        <img src="/images/logo-mitt-numedal-ressurs.svg" class="frontpage__logo">
        
        ${compile_content(html, data)}`;
        
        // console.log(aimMeta);
        
        
        
        $("#result__loadfrontpage").append(html);

    })
    
    $("#content").addClass("frontpage");
    // $("#footer").addClass("frontpage");


    return `

        
        <div class="container"  id='result__loadfrontpage'>
        </div>
        
    
    `;
}
