/*  */
import $ from "jquery";
window.jQuery = $;
// import jQuery from "jquery";
// import fancybox from "@fancyapps/fancybox/dist/jquery.fancybox.js";
require('@fancyapps/fancybox');


export const createGallery = () => {


    // Create gallery function

    var galleryid = 0;

    // console.log("working");


    $(".gallery").each(function(a, b) {

        var images = $(this).find("img");
        
        // console.log(images);


        images.addClass("gallery-image").wrap(function() {
            return "<a class='gallery-image-link'>";
        });

        
         
        
        images.parent("a").attr({
            "data-fancybox": "group",
            "rel": "gallery_" + galleryid,
            "href": function() {

                return $(this).children("img").attr("src");

            },
            "title": function() {
                var title = $(this).children("img").attr("title");
                if (typeof title == "undefined") {
                    title = $(this).children("img").attr("alt");
                }
                return title
            }
        });
        
        
        galleryid++;
 

    });
    
    // console.log("rtest" );
    // $(".gallery-image-link").fancybox(); 


}
