// import { autocomplete, checkCoverageClick,sendOrderClick,sendInterestClick,askusClick } from "./coverage_tools";
// import { sortChildren } from "./general_functions";
import { createGallery } from "./create_gallery";

import votingInit from "./calendar";

export default () => {
    
    
    const correctArticleHeight = () => {
     
        const articleHeight = $(".article__inner").height()
        const windowWidth = $(window).width();
        
        $(".article__inner").data("articleheight",articleHeight);
        
        if($("body").hasClass("transportpage")){
            if(windowWidth <= 576){
                $(".article__readmore").removeClass("d-none");
                $(".article__body__content").addClass("d-none");
                // $(".article__inner").addClass("article__shortenarticle");
            }
            else {
                $(".article__readmore").addClass("d-none");
                $(".article__body__content").removeClass("d-none");
                // $(".article__inner").removeClass("article__shortenarticle");
            }
        }
        
    };
    
    correctArticleHeight()
    
    // $( window ).resize(function() {
        
    //     correctArticleHeight();
        
    // });
    
    $(".article__readmore__button").click(function(){
        
            $(".article__readmore").addClass("d-none");
            $(".article__body__content").removeClass("d-none");
        
    });
    
    
    
        
        // document.getElementById("calendarvote");
        
        const vote = $("#calendarvote");
        
        // console.log(vote, vote.length);
        
        if(vote) {
            // console.log("vote");
            // cote.html(votingInit())
            const voteingInitHtml = votingInit();
            // console.log(voteingInitHtml);
        }
        else {
            console.log("not wote")
        }
        
    
}