export default (id) => {
    
    const templates = [
        { "name" : "Footer", "class" :  "footer", "id" : "9255eaa7-c8a7-45af-abfd-3eba9efd43be" },
        { "name" : "Forsidekampanje", "class" :  "forsidekampanje", "id" : "4e159a34-1e45-413f-b053-a2bd9f843121" },
        { "name" : "Forside", "class" :  "forside", "id" : "882f5a94-f859-49d3-b3c8-50a7c70d0f1b" },
        { "name" : "Kontaktkort Integrasjon", "class" :  "kontantkort_integrasjon", "id" : "18e51db9-304a-425f-afc0-d627a6fe9ef8" },
        { "name" : "BTI - Nivå", "class" :  "bti-niva", "id" : "aa33f116-bb07-4a93-9a27-12bc38d88efb" },
        { "name" : "BTI - Trinn", "class" :  "bti-trinn", "id" : "f63340c6-b3c3-49c0-b8c8-a661b55cd946" },
        { "name" : "Forsidebilde", "class" :  "forsidebilde", "id" : "17c15981-758d-4d09-a7ee-b2d47d2af6f0" },
        { "name" : "Referanse / sitat", "class" :  "referanse_sitat", "id" : "cd3957ad-61cc-4580-a458-e62039fb4bad" },
        { "name" : "Tjeneste", "class" :  "tjeneste", "id" : "6d0c6d38-e11d-4606-8550-d3ca77ae1ca0" },
        { "name" : "Sideinnstillinger Hvaler bredbånd", "class" :  "settings", "id" : "9cdfc60a-b123-4280-be28-16438fec40c9" },
        { "name" : "Sideinnstillinger - KR/MN", "class" :  "settings", "id" : "c26d472e-be87-4932-b641-b47c6c60b04a" },
        { "name" : "Velkomstside", "class" :  "welcomepage", "id" : "3307114c-aa59-40d2-8655-c511ae578075" },
        { "name" : "Introboks", "class" :  "introbox", "id" : "fa5d4e3b-08f0-4b77-8f92-7cd811bf1da4" },
        { "name" : "Transportside", "class" :  "transportpage", "id" : "1bb71f41-4ee6-4394-b78f-3202e2f5eeda" },
        { "name" : "Min side", "class" :  "minside", "id" : "dd8d6460-5242-404f-a8dc-c75533bd06bd" },
        { "name" : "Artikkel med bilde", "class" :  "article__with__image", "id" : "8ee596e3-98cc-43e0-b785-96cc6325773c" },
        { "name" : "Footer", "class" :  "footer", "id" : "9255eaa7-c8a7-45af-abfd-3eba9efd43be" }
    ]
    
    let r = templates.filter((obj) => {
        if(obj.id===id){
            return obj;
        }
    })
    
    if(r.length > 0){
        r = r[0];
    }
    
    return r;
}