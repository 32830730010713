import topimage from "../../templates/modules/topimage";
// import { newslistFront } from "../../templates/modules/newslist";
import { warningsFront } from "../../templates/modules/warnings";
import { getDynamicMeta } from "../general_functions";
import { getImageByContentId } from "./images";
import introbox from "../../templates/modules/introbox";

export const compile_content = (html, data) => {

    html = $("<div>" + html + "</div>");
    let includedRelatedContent = data.relatedContentObjects;

    // console.log("test x");
    // 
    
    // console.log(html.html());
    
    // html.find(".h-card").unwrap();
    // html.find("span#editorBlokk").parent("p").unwrap();
    // console.log(html.html());

    html.find("span#editorBlokk > span").each(function() {

        var id = $(this).attr("id") || false;
        var that = $(this);

        if (id) {

            $.each(includedRelatedContent, function(nr, obj) {

                if (obj.contentId === id) {

                    var html = $(`<div class="blokk" data-id="${obj.contentId}" data-author="${obj.creator}"></div>`);

                    if (obj.pageTypeId === "mal_blokk") {
                        if (obj.contentId === "dbab27c4-7c5d-4ef4-a6c7-941bbc62e85e") {
                            // Forced template;
                            let tContainer = testimonialContainer(obj);

                            // console.log("Forced");

                            html.append(tContainer);
                        }
                        else {
                            html.append(decodeURIComponent(obj.contentObject));
                        }
                    }
                    else if (obj.pageTypeId === "mal_dynamisk") {
                        let dynamicMeta = JSON.parse(decodeURIComponent(obj.metaObject));
                        
                        // console.log("dynamicMeta", dynamicMeta);

                        // if (dynamicMeta.id === "17c15981-758d-4d09-a7ee-b2d47d2af6f0") {
                        //     let topimageHtml = topimage(obj, dynamicMeta);
                        //     html.append(topimageHtml);
                        // }
                        // else if (dynamicMeta.id === "cd3957ad-61cc-4580-a458-e62039fb4bad") {
                        //     // let ref = testimonial(dynamicMeta, obj);
                        //     // html.append(ref);
                        // }
                        // else if (dynamicMeta.id === "6d0c6d38-e11d-4606-8550-d3ca77ae1ca0"){
                        //     let service = services(obj, dynamicMeta);
                        //     html.append(service)
                        // }
                        if(dynamicMeta.id === "fa5d4e3b-08f0-4b77-8f92-7cd811bf1da4"){
                            
                            let introboxHtml = introbox(obj, dynamicMeta);
                            
                            html.append(introboxHtml);
                            
                        }
                        else {
                            console.warn("no template", dynamicMeta);
                        }

                    }


                    if (obj.relatedContentObjects) {
                        if (obj.relatedContentObjects.length > 0) {

                            // console.log(html.prop("outerHTML"));

                            let children = compile_content(html.prop("outerHTML"), obj);

                            // console.log(children);
                            html = children;
                        }
                    }


                    that.parent().replaceWith(html);

                }


            });


            let working = includedRelatedContent.filter((obj) => {

                return (obj.contentId === id ? obj : false)

            });



        }


    });
    
    // let title = html.find(".article h1").text();
    
    // if(title.length > 0){
    //     html.find("h1").first().remove();    
    //     window.title = title;
    // }

    html.find("a[href*='aim:']").each(function() {
        $(this).attr("href", $(this).attr("href").replace("aim:", "/artikkel/"));
    })

    // console.log("before change", html);

    html = html.prop("outerHTML");

    if (html.match(/\[split:(.|\n)*\]/gmi)) {

        // console.log("has split");

        html.match(/\[split:(.|\n)*\]/gmi).forEach(me => {

            //   console.log(me);
            let split = me.replace("[split:</p>", "").replace("<p>]", "");

            // console.log(split);

            if (split.match("<p>||</p>")) {
                split = split.split(/<p>\|\|<\/p>/gmi);
                
                // console.log("splitting?",split);

                if(split.length > 1){
        
                    let cols = [];

                    split.forEach(col => {
                        cols.push(`
                        <div class="col">
                            ${col}
                        </div>
                        `);
                    });
                    
                    cols = cols.join("");

                    let row = `
                    <div class="row row-eq-height">
                    ${cols}
                    </div>`;
                    
                    
    
                    html = html.replace(me, row);
                }
            }

        });

    }
    

    if (html.match(/\[modul:.+\]/gmi)) {
        html.match(/\[modul:.+\]/gmi).forEach((me) => {

            let modulename = me.replace("[modul:", "").replace("]", "");

            let moduleHtml = ``;

            //   console.log(modulename);

            if (modulename === "stepguide") {

                moduleHtml = stepguide;
            }
            else if (modulename === "nyhetliste:3") {
                moduleHtml = newslistFront();
            }
            else if (modulename.match("skjema")) {
                let skjema = "kontakt";
                if (modulename.match(":")) {
                    let splitM = modulename.split(":");
                    // console.log(modulename)
                    skjema = splitM[1];
                }

                // console.log(skjema);
                if(skjema === "bestilling"){
                    moduleHtml = check_adress;
                }
                else if (skjema === "kontakt"){
                    // 
                    // console.log("contact", contact());
                    moduleHtml = contact();
                }
            }
            else if (modulename.match("beredskapsmelding")){
                
                moduleHtml = warningsFront();
                
            }
            else {
                moduleHtml = "";
                console.warn("no module", modulename);
            }

            //   console.log(me, moduleHtml);

            html = html.replace(me, moduleHtml);

        });
    }

    // console.log(html);

    return html;




}
