import {getImageByContentId} from "../../scripts/tools/images";
import {createGuid} from "../../scripts/general_functions";

export const getNews = (load = 3, from = 0, callback) => {
    
    fetch(window.apiUrl + `/api/PublicContentApi/getcontentlistdescending/${config.clientId}/mal_nyhet/${load}/${from}/Modified`).then(results => results.json()).then( data => {
 
        callback(data);

    });
}


export const newspage = () => {
    
    let list = setNewsList();
    
    
        let breadcrumbs = `
            
            <div class="bc">
                
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="bc__container">
                                <a href="/${site}" class="bc__item bc__item-link">Forside</a>
                                <span class="bc__item">Nyheter</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        `;


    
    
    return `
    
        ${breadcrumbs}
    
        <div class="newslist__page" id="newslistcontainer">
            ${list}
        </div>
    
    `;
    
}


export const setNewsList = (from, loadNum) => {

    // const breadcrumbHtml = breadcrumb();

    loadNum = loadNum || 10;
    from = from || 0;

    getNews(loadNum, from, function(result) {
        
        // console.log("News", result);

        // if (result.totalCount > loadNum) {
        //     console.log("spose need More than 25");
        // }

        let availablenews = result.totalCount;
        let to = loadNum + from;

        let newsList = [];

        $.each(result.listOfContent, function(a, b) {

            let date = new Date(b.firstCreated);
            


            let pDate = ('0' + date.getDate()).slice(-2) + '-' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                date.getFullYear();
            
            // console.log("b",b);

    
            let meta = JSON.parse(decodeURIComponent(b.metaObject));

            
            // console.log("meta", meta);

            let img = "";
            let width = "col-sm-8";

            if (meta.imageContentId !== undefined && meta.imageContentId.length > 0) {
                let imgId = createGuid();
                img = `
                    <div class="col-sm-3 image__container">
                        <img src="" alt="" class="article__introimage" id="${imgId}" >
                    </div>`;

                //console.log('getImageByContentId: + ' + meta.imageContentId);
                getImageByContentId(meta.imageContentId, (img) => {
                    if (img.url !== undefined && img.url.length > 0) {
                        $(`#${imgId}`).attr("src", "https://res.cloudinary.com/ssp/image/fetch/w_330,h_188,c_fill/"+img.url);
                    }
                });
            }
            
            // console.log("test before push");

            newsList.push(`
                <div class="newslist__item">
                    <div class="row justify-content-center">
                        ${img}
                        <div class="${width}">
                            <div class="newslist__content">
                                <h3 class="newslist__header">
                                    <a href="/${site}/${b.pageTypeId.replace("mal_","").replace("smal","")}/${b.contentId}" class="newslist__link">${b.title}</a>
                                </h3>
                                <p class="newslist__intro">${b.intro}</p>
                            </div>
                        </div>
                    </div>
                </div>`);
                
                
                                // <div class="newslist__date">${pDate}</div>
                
        });

        // $("#currentPageBreadcrumb").text(document.title);
        newsList = newsList.join("");

        $(".newslist__list").append(newsList);
        
        
        const newslist__header = `
            <div class="row justify-content-center">
                <div class="col-8  mt-5 mb-5">
                    <p>
                        Viser nyhet ${(from+1)} til ${availablenews > to ? to : availablenews} av ${availablenews}
                    </p>
                </div>
            </div>
        
        `;

        $(".newslist__info").html(newslist__header);
        
        if(availablenews > loadNum){
            
            let pages = Math.ceil(availablenews / loadNum);
            
            // console.log(pages);
            
            let pageslinks = [];
            let i = 0;
            
            for(i=0; i< pages; i++){
             
                pageslinks.push(`
                    <li class="page-item ${(i*loadNum) === from ? "disabled" : ""}"><a class="page-link news__page__links " href="#" data-from="${(i*loadNum)}" >${(i+1)}</a></li>`);   
                
            }
            
            pageslinks = pageslinks.join("");
            
            const newslist__footer = `
            
            <div class="row">
                <div class="col mt-5">
                
                    <nav aria-label="Bla i sider">
                      <ul class="pagination  pagination-lg">
                        ${pageslinks}
                      </ul>
                    </nav>
                    
                </div>
            </div>
            
            `;
            
            $(".newslist__footer").html(newslist__footer);
        }

    });
    
    $("body").off("click",".news__page__links");
    
    $("body").on("click", ".news__page__links", function(e){
        e.preventDefault();
        const num = $(this).data("from");
        setNewsList(parseInt(num));
        // $(window).scrollTo(0);
    });
    
    console.log("container", $("#newslistcontainer").length);

    const listcontainer = `
        <div class="container">
            <div class="row">
                <div class="newslist col">

                    <h1 class="page__header text-center">Nyheter</h1>
                    <div class="newslist__info"></div>
                    <div class="newslist__list"></div>
                    <div class="newslist__footer"></div>
                
                </div>
            </div>
        </div>`

    $("#newslistcontainer").html(listcontainer);
    
    return listcontainer;
    

}