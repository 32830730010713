
import {getDynamicMeta} from "../scripts/general_functions";

export default (localsettings, settings) => {
    
    
    let personvernlink = false;
    let footer = false;
    let footerInfo = "";
    let footerHtml = "";
    let sosials = "";
    
    // console.log(localsettings, settings)
    
    
    if(settings.currentDynamiskMalContent){
        settings.currentDynamiskMalContent.filter(item => {
           if(item.localId === "529c29f0-503c-47dd-8fa0-074c231bedb5"){
               personvernlink = item.value;
           }
        });
        settings.currentDynamiskMalContent.filter(item => {
           if(item.localId === "9370bd53-0ab7-4c39-b4d7-c5668182460c"){
               if(item.value.length > 0){
               footer = true;
               footerInfo = item.value;
               }
           } 
        });
    }
    
    if(localsettings.currentDynamiskMalContent){
        localsettings.currentDynamiskMalContent.filter(item => {
           if(item.localId === "9370bd53-0ab7-4c39-b4d7-c5668182460c"){
               if(item.value.length > 0){
               footer = true;
               footerInfo = item.value;
               }
           } 
        });
        
        let soc = [];
        
        // console.log(localsettings);
        localsettings.currentDynamiskMalContent.filter(item => {
           if(item.localId === "140ab843-e731-4b63-979a-8369bfbd8854" && item.value.length > 0){ // facebook
              soc.push(`<a href="${item.value}" class="footer__soc__link"><img src="/images/facebook.svg" alt="Facebook link"  class="footer__soc__img"></a>`);
           } 
        });
        localsettings.currentDynamiskMalContent.filter(item => {
           if(item.localId === "20632186-e447-4251-a78d-e525a55d4433" && item.value.length > 0){ // Twitter
              soc.push(`<a href="${item.value}" class="footer__soc__link"><img src="/images/twitter.svg" alt="Twitter link" class="footer__soc__img"></a>`);
           } 
        });
        
        sosials = soc.join("");
        
    }
    
    

    if (footerInfo.match(/\[ikon:.+\]/gmi)) {
        // console.log("test");
        footerInfo.match(/\[ikon:.+\]/gmi).forEach((me) => {
            // console.log("me",me);
            
            let iconname = me.replace("[ikon:", "").replace("]", "");
            
            // console.log(icon)

            let icon = `<span class="budlight-${iconname} replaced-icon"></span>`;

            footerInfo = footerInfo.replace(me, icon)
            
            
        });
    }
    
    
    let prefooterallalone = false
    
    if(footer){
        
        footerHtml = `
        <div class="prefooter">
            <div class="container">
                <div class="row">
                    <div class="col text-right"><a href="#top" class="prefooter__totop" aria-label="Scroll til toppen"><span class="budlight-arrow-top"></span></a></div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <h2 class="footer__header">Kontaktinformasjon</h2>
                        ${footerInfo}
                    </div>
                    <div class="col-sm-5 offset-sm-1">
                        <h2 class="footer__header">Følg oss</h2>
                        ${sosials}
                    </div>
                </div>
            </div>
        </div>
        
        
        `
        
    }
    
    else {
        prefooterallalone = true;
    }
    
        
    
    
    return `
        ${footerHtml}
        </div></div></div></div>
        <div class="postfooter ${prefooterallalone ? "all_alone" : ""}">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <a href="/${site}/artikkel/${personvernlink}" class="postfooter__link">Personvernserklæring og informasjonskapsler</a>
                    </div>
                </div>
            </div>
        </div>
    
    
    `;
    
}