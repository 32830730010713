import {getMeta} from "../general_functions";

export const getImageByContentId = (id, cb) => {

    if (id) {

        fetch(window.apiUrl + "/api/PublicContentApi/find/" + id).then(response => response.json()).then( data => {
                
            let img = getImageData(data);

            cb(img);

        });

    }

}

export const getImageData = (data) => {
    

    const meta = getMeta(data);

    let img = {
        id: data.contentId,
        url: data.path,
        filename: data.filename,
        desc: meta.BildeAlternativTekst
    }
    
    return img;
}