// import article__coverage from "./articletypes/coverage";
import article from "./articletypes/article";
import newsitem from "./articletypes/newsitem";
import {newspage} from "./modules/newslist";
import header from "./header";
import footer from "./footer";
import loadNav from "./articletypes/nav";
import catPage from "./articletypes/categorypage";
import enhet from "./articletypes/unit";
// import {newspage } from "./modules/news"


export default (settings, localsettings, articles, categories) => {

        
    localsettings = settings;
        
    let html = "";
    let content = "";
    
    // console.log(settings, articles, categories);
    
    let filtered_articles = articles.filter(elm => {
        return elm.pageTypeId.match(/mal_enhetsmal|mal_artikkel/) ? elm : null;
    })
    
    
    let nav = {articles: filtered_articles, categories: categories.children};
    

    
    window.navContent = categories.children;
    // console.log(page);
    
    let splitted_page = page.split("/");
    
    let articledef = splitted_page[splitted_page.length-1];

    let template = splitted_page[0];
    
    
    let headerHtml = header(nav, localsettings, articledef,categories);
    $("#header").html(headerHtml);
    
    
    // console.log("article, template", articledef, template);
    
    // console.log(template)
    
    if(template === ""){
        
        content = catPage(categories, nav,localsettings);
        
    }
    else if(template === "artikkel" || template === "article" || template === "nyhet"){
        
        content = article(articledef)
    }
    else if(template === "nyheter"){
        //console.log("ok?");
        content = newspage();
    }
    else if(template === "enhet"){
        
        content = enhet(articledef);
        
    }
    else if(template === "navside"){
        content = article(articledef);
    }
    else if(template === "nav"){
        
        content = loadNav(categories);
        
    }
    
    
    $("#content").html(content);
    
    
    let footerHtml = footer(localsettings, settings);
    $("#footer").html(footerHtml);
    
    
}