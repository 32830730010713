import $ from "jquery";
import tinysort from "tinysort"

export const createGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const sortChildren = () => {

    $("[data-sortchildren]").each(function(a, b, c) {

        let datavalue = $(this).data("sortchildren");

        if (datavalue === "") {
            tinysort($(this).find("[data-sort]"), { data: "sort" });
        }
        else {
            tinysort($(this).find(datavalue))
        }
    });

}

export const getMeta = (metdadata) => {
    return contentMeta(metdadata);
}
export const contentMeta = (metadata) => {

    const meta = metadata.metaObject || metadata || false;

    if (meta !== "undefined") {

        return $.parseJSON(decodeURIComponent(meta));
    }
}


export const getDynamicMeta = (meta, name, objname = "title") => { //// skal skrives ut.

    // console.log(meta.currentDynamiskMalContent);

    let result = meta.currentDynamiskMalContent.filter(obj => {

        // console.log("test",objname, name, obj[objname]);

        return obj[objname].match(name) // fjernet urlify
    });

    return result[0];

}

export const checkNested = (obj, level, ...rest) => {
    if (obj === undefined) return false
    if (rest.length == 0 && obj.hasOwnProperty(level)) return true
    return checkNested(obj[level], ...rest)
}


export const articlesExtend = (article) => {
    
    // console.log(article);
     if(article.metaObject){
        //  console.log("has meta");
            article.metaParsed = JSON.parse(decodeURIComponent(article.metaObject));
            if(article.metaParsed.currentDynamiskMalContent && article.metaParsed.currentDynamiskMalContent.length > 0){
                article.metaDynamic = {};
                article.metaParsed.currentDynamiskMalContent.forEach(cmc => {
                    article.metaDynamic[cmc.title] = cmc.value;
                })
            }
        }
        
    return article;
}