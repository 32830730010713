import $ from "jquery";

export default () => {
    //https://www.randaberg.kommune.no/innhold/hva-skjer-i-randaberg/#viewtype:item/templatetype:1/daytype:1/params:{%22Categories%22:%220%22,%22SearchText%22:%22%22,%22DateFrom%22:%22%22,%22DateTo%22:%22%22,%22Municipalities%22:%220%22,%22Kunde%22:%22randaberg%22,%22Id%22:%2224940%22,%22ItemDate%22:%2230.08.2022%22,%22WeekDays%22:%22%22,%22List%22:%22%22,%22Count%22:%22%22,%22Distributor%22:%22%22}

    const calendarContainer = document.querySelector("#calendarvote");

    calendarContainer.innerHTML = `Laster kalender...`;
        
        
    getCalendar("mittnumedal",{ number : 1000 },(items) => {
        console.log("items", items);
        
        if(items){
            
            const itemHtmlArray = [];
            
            const month = {
                "01" : "jan",
                "02" : "feb",
                "03" : "mar",
                "04" : "apr",
                "05" : "may",
                "06" : "jun",
                "07" : "jul",
                "08" : "aug",
                "09" : "sep",
                "10" : "okt",
                "11" : "nov",
                "12" : "des",
            }
            
            items.FullListOfEvents.forEach( item => {
                
                let id = 0;
                let name = "";
                let fraDato = ``;
                let time = ``;
                if(item.KalenderObjekt){
                    id = item.KalenderObjekt.Id;
                    name = item.KalenderObjekt.Name;
                    //"2022-08-30T12:00:00"
                    fraDato = `<span class="calendar__date font-size--22 font-weight--bold">
                                    ${item.FraDato.substr(8,2)}
                               </span>
                               <span class="calendar__month font-size--16 font-weight--bold">
                                    ${month[item.FraDato.substr(5,2)]}
                               </span>`;
                    if(item.KalenderObjekt.Tidspunkter){
                        if(item.KalenderObjekt.Tidspunkter[0]){
                            const fratid = item.KalenderObjekt.Tidspunkter[0].FraDato;
                            const tiltid = item.KalenderObjekt.Tidspunkter[0].TidDato;
                            
                            if(fratid && tiltid) {
                                time = fratid.substr(11,5) + " - " + tiltid.substr(11,5);
                            }
                            else if (fratid){
                                time = fratid.substr(11,5);
                            }
                        }
                    }
                    
                }
                let params = `"Categories":"0","SearchText":"","DateFrom":"","DateTo":"","Municipalities":"0","Kunde":"randaberg","Id":"${id}","ItemDate":"","WeekDays":"","List":"","Count":"","Distributor":""`;
                let url = `https://www.randaberg.kommune.no/innhold/hva-skjer-i-randaberg/#viewtype:item/templatetype:1/daytype:1/params:{${encodeURIComponent(params)}}`;
                itemHtmlArray.push(`
                    <div class="col-12 mb-4 bg--lightgray p-3">
                        <a href="${url}" class="bg--lightgray font-color--black calendar__link">
                            <h3 class="font-size--24 calendar__header mb-2">${name}</h3>                        
                        </a>
                        <div class="mb-3">
                            ${item.KalenderObjekt.Intro}
                        </div>
                        <div>
                            ${item.KalenderObjekt.Beskrivelse}
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <button class="voteforme btn btn-secondary" data-id="${item.KalenderObjekt.Id}">Stem nå!</button>
                            </div>
                            <div class="col-9 pt-1">
                                <span id="votes_${item.KalenderObjekt.Id}" class="font-weight--bold">${item.KalenderObjekt.Votes}</span> stemmer så langt
                            </div>
                        </div>
                    </div>
                `)
            })
            
            calendarContainer.innerHTML = `
            
                <div class="row  mt-4">
                    ${itemHtmlArray.join("")}
                </div>
            
            `;
            
            
            document.querySelectorAll(".voteforme").forEach(button => {
               
                button.addEventListener("click", btn => {
                    
                    const id = button.dataset.id;
                    
                    button.setAttribute("disabled", true);
                    
                    // console.log(button);
                    
                    voteNow(id);
                    
                }, false);
                
            });
            
        }
        else {
            calendarContainer.innerHTML = `Ingen aktiviteter tilgjengelig`;
        }
        
    });
    
}

export const  voteNow = (id) => {
    
    const voteNumber = document.getElementById("votes_"+id);
    const newVote = parseInt(voteNumber.innerHTML) + 1;
    const data =  {
        "Vote" : newVote,
        "Id" : parseInt(id)
    }
    
    // console.log("data", data);
        fetch('https://sspkalender.prokom.no/api/vote', { //
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                voteNumber.innerHTML = newVote;
                // console.log("ey ok");
                
            })
    
    
//     const data =  {
//     "Vote" : 4,
//     "Id" : 24821
// }
//         fetch('https://sspkalender.prokom.no/api/vote', { //
//                 method: 'POST', // or 'PUT'
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(data)
//             })
//             .then(response => response.json())
//             .then(data => {
                
//                 console.log("ey ok");
                
//             })
    
}



export const getCalendar = (client, obj = { number: 4, id: false }, callback) => {

    let id = obj.id || "";
    let number = obj.number;
    let category = obj.category || ""; //(obj.category ? obj.category +","+ client.calendar.localid : client.calendar.localid);

    // console.log("tests",category);

    // https://sspkalender.prokom.no/api/kalender?Categories=&SearchText=&DateFrom=&DateTo=&Municipalities=&Kunde=kongsbergregionen&Id=19839&ItemDate=&WeekDays=&List=&Count=&Distributor=

    const gc = $.getJSON("https://sspkalender.prokom.no/api/tidspunkt?Categories=" + category + "&SearchText=&DateFrom=&DateTo=&Municipalities=&Kunde=" + client + "&Id=" + id + "&ItemDate=&WeekDays=&List=compact&Count=" + number + "&Distributor=").done((cal) => {
        // console.log(cal);
        callback(cal);


    });

    return gc;

};
