
import dayjs from "dayjs";
import 'dayjs/locale/nb'; // load on demand

export const getWarnings = (callback) => {
    
    fetch(window.apiUrl + `/api/PublicContentApi/getcontentlistdescending/${config.clientId}/mal_beredskap/3/0/Modified`).then(results => results.json()).then( data => {
 
        callback(data);

    });
}

export const warningsSubpage = () => {
    
    let more = ``;
    
    getWarnings((data) => {
        
        // console.log(data);
        
        data.listOfContent.forEach((item) => {
            
            let meta = JSON.parse(decodeURIComponent(item.metaObject));
        
            // console.log("item", item, meta);
            
            if(meta.beredskapVisPaaSide === "Hele portalen"){

            $("#warnings").append(`
                        <div class="message">
                            <h2 class="message__title">${item.title}</h2>
                            <div class="message__content">${decodeURIComponent(item.contentObject)}</div>
                            <span class="message__date">${item.modified}</span>
                        </div>`);
            }
        })
    })
    
    return ` <div class="container">
            <div class="message__container">
                <div class="row">
                    <div class="col" id="warnings">
                    
                    
                    </div>
                </div>
            </div>
        </div>`;
}

export const warningsFront = () => {
    
    let more = ``;
    
    getWarnings((data) => {
        
        // console.log(data);
        
        data.listOfContent.forEach((item) => {
            
            let meta = JSON.parse(decodeURIComponent(item.metaObject));
        
            // console.log("item", item, meta);
            
            if(meta.beredskapVisPaaSide === "Hele portalen" || meta.beredskapVisPaaSide === "Forside"){

            $("#warnings").append(`
                        <div class="message">
                            <h2 class="message__title">${item.title}</h2>
                            <div class="message__content">${decodeURIComponent(item.contentObject)}</div>
                            <span class="message__date">${dayjs(item.modified).format("DD.MM.YYYY")}</span>
                        </div>`);
            }
        })
    })
    
    return ` <div class="container">
            <div class="message__container">
                <div class="row">
                    <div class="col" id="warnings">
                    
                    
                    </div>
                </div>
            </div>
        </div>`;
        
}
        