import $ from "jquery";
import { compile_content } from "../../scripts/tools/compile_content.js";
import { set_accordian } from "../../scripts/accordian.js";
import { createGallery } from "../../scripts/create_gallery";
import postload_actions from "../../scripts/postload_actions.js";
import { warningsSubpage } from "../modules/warnings.js";
import { getcontent, getArticlesByCat } from "../../scripts/tools/getcontent.js";
import { createGuid, articlesExtend, checkNested } from "../../scripts/general_functions";
import { getImageData, getImageByContentId } from "../../scripts/tools/images.js";
import { getTransportpages } from "../../scripts/tools/getcontent";
import matchTemplate from "../../scripts/tools/template_convert";
import { navItemHtml, getSpecificCategory } from "./nav";
export default (articledef) => {


    getcontent(articledef, function(data) {

        // console.log("datafirst",data);X




        let content = decodeURIComponent(data.contentObject);


        if (content.match(/\[modul:videresend:(.|\n)*\]/gmi)) {

            content.match(/\[modul:videresend:(.|\n)*\]/gmi).forEach(me => {
                let url = me.replace("[modul:videresend:", "").replace("]", "");

                // console.log("videresend", url );

                window.parent.location = url;
            })

            return;
        }

        content = compile_content(content, data);
        content = set_accordian(content);

        let warnings = warningsSubpage();
        // let title = window.title ? window.title : data.title;



        let articleMeta = {};
        if (data.metaObject) {
            articleMeta = JSON.parse(decodeURIComponent(data.metaObject));
            data.metaParsed = JSON.parse(decodeURIComponent(data.metaObject));

            if (data.metaParsed.currentDynamiskMalContent) {
                if (data.metaParsed.currentDynamiskMalContent.length > 0) {

                    data.metaDynamic = {};

                    data.metaParsed.currentDynamiskMalContent.forEach(metaitem => {

                        data.metaDynamic[metaitem.title] = metaitem.value;

                    });

                }
            }
        }


        let title = data.title;
        let template = data.pageTypeId.replace("mal_", "").replace("smal", "");

        if (template == "dynamisk") {
            console.log(data);
            if (checkNested(data, "metaParsed", "id")) {
                template = matchTemplate(data.metaParsed.id).class;
                console.log("test 2", template)
            }

        }

        $("head title").text(title + " - Kongsbergregionen");
        
        $("body").addClass(template);

        // console.log(title)
        // if ($(content).find("h1").length > 0) {
        //     let temp = $(content);
        //     title = temp.find("h1").first().text();
        //     temp.find("h1").first().remove();
        //     content = temp.prop("outerHTML");
        //     // content = content.html();
        // }


        // let si


        // console.log("con", site);

        // console.log("site", site);

        let breadcrumbs = `
            
            <div class="bc">
                
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col">
                            <div class="bc__container">
                                <a href="/${site}" class="bc__item bc__item-link">Forside</a>
                                <span class="bc__item">${title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        `;


        $("#currentpage").html(title);



        let guid = createGuid();
        let image = "";

        // console.log("data modified", data);

        if (articleMeta.imageContentId) {

            // console.log("true");
            // <img src="/dist/images/logosymbol-vannmerke-full.svg" class="article__mainimage__watermark" alt="">
            image = `
                    <div class="article__mainimage__container text-center text-sm-left ">
                        <img src="" id="article__image__${guid}" class="article__mainimage article__mainimage-${template}" alt="" >
                        ${template === "transportpage" ? `<div class="article__mainimage__watermarkcontainer"></div>` : ""}
                    </div>`


            getImageByContentId(articleMeta.imageContentId, data => {

                //   console.log("imagedata",data);

                $("#article__image__" + guid).attr("src", "//res.cloudinary.com/ssp/image/fetch/w_1100,c_fill/" + data.url);


            });

        }

        let rightColumn = [];

        if (articleMeta.currentDynamiskMalContent) {

            rightColumn = articleMeta.currentDynamiskMalContent.filter(item => {
                return (item.localId === "ec651189-81a1-4c36-aa59-fc6b63c4ce47" ? item : null)
            })

            // console.log(rightColumn, rightColumn.length);
        }

        // console.log

        if (rightColumn.length > 0) {

            rightColumn = rightColumn[0].value;

        }
        else {
            rightColumn = false;
        }

        // console.log("data full",data);

        // if transport 
        if (data.metaParsed) {
            if (data.metaParsed.id === "1bb71f41-4ee6-4394-b78f-3202e2f5eeda" || data.metaParsed.contentTypeId === "1bb71f41-4ee6-4394-b78f-3202e2f5eeda") {

                rightColumn = `
                    <div id="transportlinks">
                    </div>`;


                // let categoryId = data.categories[0].categoryId;

                // let categoryId = getUrlAttr("categoryid");
                let categoryId = data.categories[0].categoryId;
                if (categoryId.length > 0) {


                    let articleNav = getSpecificCategory(navContent, { categoryId: categoryId })

                    if (articleNav.children) {
                        articleNav.children.forEach(navitem => {


                            getTransportpages(navitem.categoryId, items => {


                                let catnavs = [];


                                if (items.length > 0) {
                                    items.forEach(transportPage => {

                                        let desc = "";
                                        if (checkNested(transportPage, "metaDynamic", "Intro")) {
                                            desc = transportPage.metaDynamic.Intro
                                        }

                                        let catnavHtml = navItemHtml({
                                            modified: transportPage.modified,
                                            type: "navside",
                                            title: transportPage.title,
                                            url: transportPage.nameInUrl + "?categoryid=" + navitem.categoryId,
                                            desc: desc
                                        });

                                        if (transportPage.contentId !== data.contentId)
                                            $("#transportlinks").append(`<a href="/${site}/navside/${transportPage.nameInUrl}">${transportPage.title}</a>`); //?categoryid=${navitem.categoryId}

                                    })
                                }
                                else {
                                    // catnavs.push(catHtml);
                                    // resolve(catHtml);
                                }

                            });

                        });
                    }

                    getArticlesByCat(categoryId, articles => {

                        articles.forEach(articleLinks => {

                            articleLinks = articlesExtend(articleLinks);

                            if (articleLinks.metaParsed) {
                                if (articleLinks.metaParsed.id === "1bb71f41-4ee6-4394-b78f-3202e2f5eeda") {
                                    return;
                                }
                            }

                            let type = articleLinks.pageTypeId.replace("mal_", "").replace("smal", "");

                            $("#transportlinks").append(`<a href="${site}/${type}/${articleLinks.nameInUrl}">${articleLinks.title}</a>`);

                        })



                    });
                }

            }

        }


        if (rightColumn) {


            rightColumn = `
            
                <div class="col-sm-4 offset-sm-1 article__rightcolumn">
                    ${rightColumn}
                </div>
            
            `;

        }


        let intro = "";
        if (data.intro) {
            intro = data.intro;
        }
        else if (data.metaDynamic) {
            if (data.metaDynamic.Intro) {
                intro = data.metaDynamic.Intro;
            }
        }

        $("#pagecontent").append(`
        
            ${breadcrumbs}
        
            ${warnings}
        
             
            <div class="article__content ">
                <div class="container">
                    <div class="row justify-content-sm-center">
                        <div class="${template !== "transportpage" ? "col-sm-7" : "col"}">
                            ${image}
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-sm-7 article__inner ${template} ">
                        
                        
                            <h1 class="article__header__title article__header__title-${template}  ${template === "transportpage" ? "text-center text-sm-left" : ""}">${title}</h1>
                            
                            ${intro ? `<p class="article__header__intro article__header__intro-${template}">${intro}</p>` : ""} 
                        
                            <div class="article__body__content">${content}</div>
                            
                            <p class="article__readmore d-none"><button class="article__readmore__button">Vis hele artikkelen</button></p>
                                
                        </div>
                        ${rightColumn ? rightColumn : ""}
                    </div>
                </div>
            </div>
        `);


        postload_actions();

        createGallery();

    });

    return `
        <article id="pagecontent">
        </article>
    `;


}
