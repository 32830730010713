// Style
import './style/style.scss';

//script
import $ from "jquery";
// import "fantdu/dist/fantdu.min";
import { cookieRead, cookieDelete } from "./scripts/tools/cookie_control";
import start from "./templates/index";


window.getUrlAttr = function(attr) {
    return new URL(window.location.href).searchParams.get(attr);
}

window.apiUrl = "https://prokom-aimcms.azurewebsites.net";
window.dev = false;

if (window.location.href.match(/\?dev/gi)) {
    window.apiUrl = "https://prokom-aimcms-qa.azurewebsites.net";
    window.dev = true;
}


if (cookieRead("RuntimeMode") == "dev") {
    window.apiUrl = "https://prokom-aimcms-qa.azurewebsites.net";
    window.dev = true;
    console.info("RuntimeMode is set to %cdev", "background-color: #e0005a; color: #ffffff; font-weight: bold; padding: 4px;");
}

if (cookieRead("RuntimeMode") == "release") {
    window.apiUrl = "https://prokom-aimcms-release.azurewebsites.net";
    window.dev = true;
    console.info("RuntimeMode is set to %crelease", "background-color: #e0005a; color: #ffffff; font-weight: bold; padding: 4px;");
}

window.contentID = "";

let host = window.location.host;


window.urlify = (string) => {

    if (string !== null) {
        string = string.replace(/\s|\,|-/gi, "-").replace(/\?|\#/gmi, "").replace(/[å]/gi, "aa").replace(/[å]/gi, "ae").replace(/[ø]/gi, "o").replace(/[_]{2,}/gi, "").toLowerCase();
    }
    // console.log("urlify string", string);
    return string;

}


window.getPageUrl = function() {

    let urldivert = location.pathname.substr(1).split(/\/(.+)/);
    let template = urldivert[0] || "frontpage";
    let contentid = urldivert[1] || "";

    return { template: template, contentid: contentid, path: urldivert };

}

if (document.referrer.match(/(https:\/\/aim-admin.azurewebsites.net)/gi)) {
    sessionStorage.setItem("fromcms", true);
}



document.addEventListener('DOMContentLoaded', function(e) {



    let data = { "clientDomainName": window.location.hostname };

    if(window.location.hostname === 'localhost') {
        data = { "clientDomainName": "mittnumedal.aim.prokom.no" };
    }

    fetch('https://rolemanager.prokom.no/api/admin/organization/getclientbydomain', { //
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {

            if (data.result) {

                // console.log(data);

                window.config = data.result;

                window.aimMeta = JSON.parse(data.result.applications[0].metaData).aim;

                console.log("aimMeta: ", aimMeta);

                start();
            }

        });


});
