import { getImageByContentId } from "../../scripts/tools/images";
import { createGuid } from "../../scripts/general_functions";

export default (data, meta) => {
    
    // console.log("data", data, meta);
    let id = createGuid();
    
    let intro = "";
    let pretitle = false;
    meta.currentDynamiskMalContent.filter (dd => {
        if(dd.localId === "a55f452b-78ae-4ff4-a937-73efb539dc3e"){
            return intro = dd.value;
        }
    })
    meta.currentDynamiskMalContent.filter (dd => {
        if(dd.localId === "415aa4d0-4326-4edc-83ed-97d44245ebd8"){
            return pretitle = `<span class="introbox__pretitle">${dd.value}</span>`;
        }
    })
    
    
    // console.log("intro", data)
    
    if(meta.imageContentId) {
        
        getImageByContentId(meta.imageContentId, function(img){
            
            // console.log("Img", img);
            
            $("#"+id).attr("src", img.url);
            
        });
        
    }
    
    
            // <div class="introbox__image__container">
            //     <img src="" class="introbox__image" id="${id}" alt="">
            // </div>
    let r = `
    
        <a href="/${data.nameInUrl}" class="introbox__link h-100">
            <div class="introbox__content">
                ${pretitle ? pretitle : ""}
                <h2 class="introbox__title ${pretitle ? "topspace" : ""}">${data.title}</h2>
                <p class="introbox__intro">${intro}</p>
            </div>
            <div class="introbox__guide"><span class="budlight-arrow-right"></span></div>
        </a>
    
    `;
    
    // r = "test";
    
    return r;
    
    
}