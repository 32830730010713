import $ from "jquery";
import { compile_content } from "../../scripts/tools/compile_content.js";
import { set_accordian } from "../../scripts/accordian.js";
import postload_actions from "../../scripts/postload_actions.js";
import { warningsSubpage } from "../modules/warnings.js";
import { createGuid } from "../../scripts/general_functions";
import { getcontent } from "../../scripts/tools/getcontent.js";
import { getImageByContentId } from "../../scripts/tools/images";
// import { createGuid } from "../../scripts/general_functions";

export default (articledef) => {


    getcontent(articledef, function(data) {

        // console.log(data);

        let content = decodeURIComponent(data.contentObject);

        content = compile_content(content, data);
        content = set_accordian(content);

        let guid = createGuid();

        let warnings = warningsSubpage();
        // let title = window.title ? window.title : data.title;


        let title = data.title;

        $("head title").text(title + " - Kongsbergregionen");

        // console.log(title)
        // if ($(content).find("h1").length > 0) {
        //     let temp = $(content);
        //     title = temp.find("h1").first().text();
        //     temp.find("h1").first().remove();
        //     content = temp.prop("outerHTML");
        //     // content = content.html();
        // }

        // console.log("artikkel", data);

        let meta = JSON.parse(decodeURIComponent(data.metaObject));

        // console.log("meta", meta);

        let img = `<div id="img_${guid}"></div>`;

        if (meta.imageContentId) {

            getImageByContentId(meta.imageContentId, function(img) {

                // console.log("Img", img);

                $("#img_" + guid).html(`
                    <div class="row mt-5">
                        <div class="col-12">
                            <img src="${img.url}" alt="" >
                        </div>
                    </div>`)

            });

        }


        let breadcrumbs = `
            
            <div class="bc">
                
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="bc__container">
                                <a href="/${site}" class="bc__item bc__item-link">Forside</a>
                                <span class="bc__item">${title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        `;



        $("#currentpage").html(title);

        let headertop = `
        
            ${breadcrumbs}
        
            <div class="article__header">
                <div class="container">
                    
                    ${img}
                
                    <div class="row ">
                        <div class="col-12">
                            <h1 class="article__header__title">${title}</h1>
                            <p class="article__header__intro">${data.intro}</p>
                        </div>
                    </div>
                </div>
            </div>`;
        // let inlineheader = "";
        // 

        let contactcard = [];

        // console.log(meta);

        if (meta.kontaktkort) {
            if (meta.kontaktkort.length > 0) {

                // console.log(meta.kontaktkort.kontaktkort_tittel);


                meta.kontaktkort.forEach(card => {

                    if (card.kontaktkort_tittel) {
                        // console.log(card);

                        // card.kontaktkort_postadresse = false;

                        let email = card.kontaktkort_epost ? `
                            <label class="contact__label">E-post:</label>
                            <a href="mailto:${card.kontaktkort_epost}">${card.kontaktkort_epost}</a>` : "";

                        let name = card.kontaktkort_personnavn ? `<h3 class="contact__name">${card.kontaktkort_personnavn}</h3>` : "";

                        let post = card.kontaktkort_postadresse ? `
                            <label class="contact__label">Postadresse:</label>
                            ${card.kontaktkort_postadresse}` : "";

                        let phone = card.kontaktkort_telefon ? `
                            <label class="contact__label">Telefon:</label>
                            <a href="tel:${card.kontaktkort_telefon.replace(/\s/gmi,"")}">${card.kontaktkort_telefon}</a>` : "";

                        let title = card.kontaktkort_tittel ? `<span class="contact__role">${card.kontaktkort_tittel}</span>` : "";

                        let visit = card.kontaktkort_visitingaddress ? `
                            <label class="contact__label">Besøksadresse:</label>
                            <a href="https://www.google.com/maps/place/${card.kontaktkort_visitingaddress}">${card.kontaktkort_visitingaddress}</a>` : "";


                        if (card.kontaktkort_posisjonsdata) {

                            let address = card.kontaktkort_posisjonsdata.adressetekst + ", " + card.kontaktkort_posisjonsdata.postnummer + " " + card.kontaktkort_posisjonsdata.poststed;

                            visit = `
                            <label class="contact__label">Besøksadresse:</label>
                            <a href="https://www.google.com/maps/place/${address}" target=_blank>${address}</a>`;

                        }



                        contactcard.push(`
                        
                            <div class="contact">
                                <h2 class="contact__title">Kontakt</h2>
                                ${name}
                                ${title}
                                
                                ${phone}
                                
                                ${email}
                                
                                ${visit}
                                
                                ${post}
    
                            </div>
                        
                        `);
                    }
                });

            }
        }

        contactcard = contactcard.join("");

        let siteref = ""

        if (meta.enhetsId) {

            siteref = `<div class="contact"><a href="${meta.enhetsId}" class="contact__primarylink">Lenke til hjemmeside<span class="budlight-arrow-right"></a></div>`;

        }

        // console.log("contactcard", contactcard);


        $("#pagecontent").append(`
        
            ${warnings}
        
            ${headertop}
             
            <div class="article__content ">
                <div class="container">
                    <div class="row ">
                        <div class="col-sm-7 article__inner">
                        
                            ${content}
                                
                        </div>
                        <div class="col-sm-4 offset-sm-1">
                            ${contactcard}
                            ${siteref}
                        </div>
                    </div>
                </div>
            </div>
        `);


        postload_actions();


    });

    return `
        <article id="pagecontent">
        </article>
    `;


}
