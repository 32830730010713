import $ from "jquery";
// import tinysort from "tinysort";
import { getDynamicMeta } from "../scripts/general_functions";

export default (nav, settings, article, cat) => {

    let pages = [];

    nav.categories.forEach((page) => {

        // console.log("header nav", page);

        // if (page.pageTypeId === "mal_artikkel" || page.pageTypeId === "mal_enhetsmal") {
        pages.push(`<div class="col-sm-4 header__nav__linkcontainer">
                        <a href="${page.externallink !== null ? page.externallink : `/${site}/nav/${urlify(page.item)}`}" class="header__nav__link h-100" data-sort="${page.item}">${page.item}<span class="budlight-arrow-right header__nav__icon float-right"></span></a>
                    </div>`);
        // }

    });
    nav.articles.forEach((page) => {

        // console.log("header nav", page.pageTypeId, page.title);

        if (page.isVisibleInNavigation && (page.pageTypeId === "mal_artikkel" || page.pageTypeId === "mal_enhetsmal")) {
            pages.push(`<div class="col-sm-4">
                        <a href="/${site}/${page.pageTypeId.replace("mal_","").replace("smal","")}/${page.contentId}" class="header__nav__link" data-sort="${page.title}">${page.title} <span class="budlight-arrow-right header__nav__icon float-right"></span></a>
                    </div>`);
        }

    });

    let links = [];

    // let headerlinks = [];
    // let bool_tjenester = getDynamicMeta(settingsMeta, "c3d61a40-1f4b-42f1-b79b-d2abf7329247", "localId");
    // if(treu 0){
    //      links.push(` <a href="/artikkel/71900e74-1533-408a-b218-698ec75887b4" class="header__link d-none d-md-inline-block ">Tjenester</a>`)
    // // }
    // let bool_sportoss = getDynamicMeta(settingsMeta, "b531d312-47e0-4cf0-a830-a83248de547e", "localId");
    // if(bool_sportoss){
    //      links.push(` <a href="/artikkel/${bool_sportoss.value}" class="header__link d-none d-md-inline-block">Spør oss</a>`)
    // }

    // tinysort(pages, "a", { data:"title" });

    // console.log(nav, settings);

    let logo = "";
    let nologo = false;
    let simulated_logo = "";

    // console.log("settings", settings, JSON.parse(decodeURIComponent(settings.metaObject )));



    // console.log("go?")

    if (settings) {
        //     if (settings.logo) {

        //         let placement = "";

        //         if(settings.logodata){
        //             placement = settings.logodata;

        //             // console.log(settings);
        //         }

        //         logo = `
        //             <div class="col">
        //                 <a href="/${site}" class="header__logo__link" title="Lenke til forsiden for tema">
        //                     <img src="/dist/images/mitt-numedal-logo.svg" class="header__logo__img" alt="Logo">
        //                 </a>
        //             </div>`
        //     }
        //     else {
        //         nologo = true;
        //     }

        //     // console.log(settings.logo);

        if (settings.currentDynamiskMalContent) {
            settings.currentDynamiskMalContent.filter(item => {
                return item.localId === "1dbe9213-c3db-4038-8b4d-fad8906f0fc0" && item.value.length > 0 ?
                    links.push(` <a href="/${site}/artikkel/${item.value}" class="headernav__link d-none d-md-inline-block">Kontakt oss</a>`) :
                    null
            })
        }


    }
    // else {
    //     nologo = true;
    // }




    // if(article.length > 0 && logo === ""){
    //     simulated_logo = `

    //         <div class="col ">
    //             <div class="header__simlogo ">
    //                 ${cat.item}
    //             </div>
    //         </div>

    //     `;
    // }


    links = links.join("");
    pages = pages.join("");

    $("body").on("click", ".headernav__navtrigger-open", function() {

        $(".header__nav__blackout").removeClass("d-none");

    });
    $("body").on("click", ".headernav__navtrigger-close", function() {

        $(".header__nav__blackout").addClass("d-none");

    });
    // alert("rtest");

    // ${simulated_logo}
    // ${nologo ? "nologo" : ""}
    return `
    
    <div class="header__logo__container ">
        <div class="container-fluid header">
            <div class="row">
                <div class="col">
                    <a href="/${site}" class="header__logo__link" title="Lenke til forsiden for tema">
                        <img src="/images/mitt-numedal-logo.svg" class="header__logo__img" alt="Logo">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="headernav">
        <div class="row">
            <div class="col header__linkcollection text-right">
                ${links}
                <button class="headernav__navtrigger headernav__navtrigger-open" aria-label="Åpne meny" aria-controls="mainmenu" ><img src="/images/icon-navigation-menu.svg" alt="" class="headernav__navtrigger__icon"><span class="d-none d-sm-inline">Meny</span></button>
            </div>
        </div>
    </div>
    <div class="header__nav__blackout d-none ">
    
        <div class="header__nav " id="mainmenu" aria-live="polite">
            <div class="row">
                <div class="col text-right">
                    <button class="headernav__navtrigger headernav__navtrigger-close" aria-label="Åpne meny" aria-controls="mainmenu" ><img src="/images/icon-navigation-close.svg" alt="" class="headernav__navtrigger__icon"><span class="d-none d-sm-inline">Lukk</span></button>
                </div>
            </div>
            <nav role="navigation" class="header__nav__pages">
                <div class="container">
                    <div class="row row-eq-height" data-sortchildren="a">
                        ${pages}
                    </div>
                </div>
            </nav>
    </div>
    
    
    </div>
    
    `;

}
