import $ from "jquery";
import header from "./header";
import footer from "./footer";
import frontpage from "./articletypes/frontpage";
import pagetype from "./page";
// import { navToggle } from "../scripts/nav";
import postrender_actions from "../scripts/postrender_actions";
import { getImageByContentId } from "../scripts/tools/images";

export default () => {

    // let 

    let pagesFirstlevel = JSON.stringify({
        "categoryId": "ef9768d2-e273-41d8-9e0d-ffa688d0823a",
        "subscriptionId": config.clientId
    });


    // login løsning
    if (sessionStorage.getItem("fromcms") === "true" && getUrlAttr("preview")) {

        //console.log("yeay");


        sessionStorage.setItem("loginRedirect", window.location.href);

        var config_oidc = {
            authority: "https://identity.prokom.no",
            client_id: "AimPreviewClient",
            redirect_uri: location.origin + "/callback.html",
            response_type: "code",
            scope: "openid profile aim_api",
            post_logout_redirect_uri: location.origin,
        };

        var mgr = new Oidc.UserManager(config_oidc);


        mgr.getUser().then(function(user) {
            if (user) {
                mgr.getUser().then(function(user) {
                    sessionStorage.setItem("access_token", user.access_token);
                });

                function logout() {
                    mgr.signoutRedirect();
                }

            }
            else {
                mgr.signinRedirect();
            }
        });



        $("body").append(`
                <div class="alert alert-warning fixed-bottom mb-0" role="alert">
                    <div class="container"><div class="row"><div class="col-12">
                        Du er nå på forhåndsvisning av siden. Klikk <a href="${location.origin}${location.pathname}">her</a> for å se live versjon.
                    </div></div></div>
                </div>`);

    }

    // console.log("Yea")
    
    // console.log(config);

    $.when(
        $.getJSON(window.apiUrl + "/api/PublicContentApi/menucategory/" + config.clientId),
        // $.ajax({
        //     type: "POST",
        //     url: window.apiUrl + "/api/PublicContentApi/getbycategoryidasstring",
        //     contentType: "application/json-patch+json",
        //     data: pagesFirstlevel
        // }),
        $.getJSON(window.apiUrl + "/api/PublicContentApi/find/" + aimMeta.settings) // set settingsid
    ).done(function(
        navdata,
        // pages, 
        settings) {



    
        // settings
        settings = settings[0];
        let settingsMeta = JSON.parse(decodeURIComponent(settings.metaObject));
        // 
        // console.log(settings, settingsMeta);
        // 
        navdata = navdata[0];
        // pages = pages[0];

        // let nav = {navdata, pages};
        // let headerHtml = header(nav, settingsMeta);
        // $("#header").append(headerHtml);
        // navToggle();

        let url = getPageUrl();

        window.site = url.template;
        window.page = url.contentid;

        // console.log({site, page, url, navdata});

        // console.log(site, page)

        let localnav = false;
        
        
        // console.log(navdata);

        navdata.filter(nav => {
            
            // console.log(urlify(nav.item),site);
            
            if (urlify(nav.item) === site)
                return localnav = nav;
        })

        // console.log("localnav",localnav);


        let html = ``;

        // console.log(site);
    // console.log("magi 2");

        if (site === "frontpage") {
    // console.log("magi 3");
            // console.log("start frontpage");
            html = frontpage(localnav);
            // 
            // console.log()
            // 
            let ls = JSON.parse(decodeURIComponent(settings.metaObject));
            
            let footerHtml = footer(ls, settings);
            $("#footer").html(footerHtml);
        }
        else {
    // console.log("magi 4", localnav);

            if (localnav) {

    // console.log("magi 4");
                // console.log("localnav inside", localnav)

                // let getcontent = window.apiUrl+"/api/PublicContentApi/getbycategoryid/"+localnav.categoryId+"/"+config.clientId;

                // console.log(getcontent)

                // $.get(getcontent).done(function(datadd){

                // //   console.log("datadd", datadd);

                // });
                
                let query = JSON.stringify({
                        "categoryId": localnav.categoryId,
                        "subscriptionId": config.clientId
                    });
                    

                $.ajax({
                    type: "POST",
                    url: window.apiUrl + "/api/PublicContentApi/getbycategoryidasstring",
                    contentType: "application/json-patch+json",
                    data:  query
                }).done(function(alledata) {
                    
                    
                    
                    // console.log("data",alledata)
                    
                    
                    let localsettings = false;
                    
                    alledata.filter(elm => {
                        if(elm.title.match(/innstillinger/gmi)){
                            localsettings = JSON.parse(decodeURIComponent(elm.metaObject));
                            // console.log("andre ting", elm)
                        }
                    })
                    
                    
                    // console.log("data",alledata)
                    
                    // console.log(settingsMeta.currentDynamiskMalContent, localsettings.currentDynamiskMalContent);
                    
                    // let mergedcurrentDynamiskMalContent = Object.assign(settingsMeta.currentDynamiskMalContent, localsettings.currentDynamiskMalContent);
                    
                    // let mergedSettings = Object.assign(settingsMeta, localsettings);
                    
                    // mergedSettings.currentDynamiskMalContent = mergedcurrentDynamiskMalContent;
                    
                    // console.log(localsettings);
                    
                    if(localsettings.imageContentId){
                        getImageByContentId(localsettings.imageContentId, function(img){
                            
                            localsettings.logo = img.url;
                            
                            localsettings.logodata = img;
                            
                            html = pagetype(settingsMeta, localsettings, alledata, localnav);
                            
                            // console.log("ready",localsettings, alledata, localnav);
                            
                        });
                    }
                    else {
                        html = pagetype(settingsMeta, localsettings, alledata, localnav);
                    }
                    // console.log("alledata",alledata)

                });


            }
            else {
                console.warn("[ Ingen kategroi matchet sidenavnet ]")
            }
        }
        // else if (page === "sjekkdekning ") {
        //     html = pagetype("coverage");
        // }
        // else if (page === "article" || page === "artikkel") {
        //     html = pagetype("article");
        // }
        // else if (page === "nyhet"  && id) {
        //     html = pagetype("newsitem");
        // }
        // else if (page === "nyheter") {
        //     html = pagetype("newslist");
        // }


        $("#content").append(html);

        postrender_actions();
        

        // let footerHtml = footer(settingsMeta);

        // $("#footer").append(footerHtml);

    });

}
