import { getList } from "../../scripts/tools/getcontent.js";
import { createGuid, checkNested } from "../../scripts/general_functions";
import { getImageByContentId } from "../../scripts/tools/images";
import { getTransportpages } from "../../scripts/tools/getcontent";
import { navItemHtml, navigation } from "./nav";
import dayjs from "dayjs";
import tinysort from "tinysort";
import 'dayjs/locale/nb'; // load on demand

export default (cat, nav, settings) => {

    // console.log(cat);

    let name = cat.item;
    let desc = `<p class="cpheader__desc">${cat.description}</p>` || ""

    let nologo = false;
    if (settings) {
        if (!settings.logo) {
            nologo = true;
        }
    }
    else {
        nologo = true;
    }

    // header 

    getList({
        type: "mal_dynamisk"
    }, function(data) {

        let desc = false;

        let siteSettings = data.listOfContent.filter(point => {
            if (point.nameInUrl === site)
                return point;
        })

        siteSettings = siteSettings[0];

        siteSettings.metaParsed = JSON.parse(decodeURIComponent(siteSettings.metaObject));

        if (siteSettings.metaParsed.currentDynamiskMalContent && siteSettings.metaParsed.currentDynamiskMalContent.length > 0) {
            siteSettings.metaDynamic = {};
            siteSettings.metaParsed.currentDynamiskMalContent.forEach(cmc => {
                siteSettings.metaDynamic[cmc.title] = cmc.value;
            })
        }

        if (siteSettings.metaDynamic) {
            if (siteSettings.metaDynamic.Forsidetekst) {
                desc = siteSettings.metaDynamic.Forsidetekst;
            }
        }

        let cpheaderimgId = createGuid();

        if (siteSettings.metaParsed) {
            getImageByContentId(siteSettings.metaParsed.imageContentId, function(img) {

                let imgurl = `https://res.cloudinary.com/ssp/image/fetch/w_263,h_263,c_fill,ar_1,c_fill,r_max,f_png/${img.url}`

                // $("#img__" + cpheaderimgId).attr("src", ).attr("alt",img.imageDescription);

                let imgHtml = `
                
                <div class="col-sm-3 text-center order-1 order-sm-12 text-sm-right">
                    <img src="${imgurl}" data-loaded="" class="cpheader__img" alt="${img.imageDescription}" />
                    <img src="/images/logosymbol-vannmerke-full.svg" class="cpheader__img__hart">
                </div>
                    `;

                $("#img__" + cpheaderimgId).append(imgHtml);

            });
        }

        const cpheaderHtml = `
    
        <a href="/" class="cpheader__backlink"><span class="budlight-arrow-left"></span>Til velkomstside</a>
    
    
        <div class="container">
            
            <div class="row justify-content-center" id="img__${cpheaderimgId}">
                <div class="col-sm-9  order-12 order-sm-1 align-self-center text-center ${desc ? "text-sm-left" : "text-sm-center"}" >
                    <h1 class="cpheader__title">${siteSettings.title}</h1>
                    <p class="cpheader__desc">${desc ? desc : ""}</p>
                </div>
            </div>
        
        </div>`;

        $("#cpheader__html").html(cpheaderHtml);


    });
    
    // navigation 

    navigation(nav.categories, nav.articles, "#categorylinks")


    let news = [];


    // console.log(cat);
    

    getList({
        type: "mal_nyhet",
        category: cat.categoryId
    }, function(data) {

        // console.log("nyheter",data);

        let i = 0;
        let max = 3;

        data.listOfContent.forEach(newsitem => {

            let newsitemincategory = newsitem.categories.filter(ni => { 
                
                // console.log(ni, cat.categoryId);
                
                return ni.categoryId === cat.categoryId ? true : false 
                
            });
            let guid = createGuid();

            if (newsitemincategory.length > 0) {

                if (i < max) {

                    let newsMeta = JSON.parse(decodeURIComponent(newsitem.metaObject));
                    if (newsMeta.imageContentId) {
                        getImageByContentId(newsMeta.imageContentId, function(img) {

                            $("#img__" + guid).attr("src", "https://res.cloudinary.com/ssp/image/fetch/w_330,h_188,c_fill/"+img.url);

                        })
                    }

                    news.push(` 
                        <div class="col-sm-4 mb-3">
                            <a href="/${site}/nyhet/${newsitem.nameInUrl}" class="cpnews__item h-100">
                                <img src="" class="cpnews__item__img" id="img__${guid}" alt="">
                                <div class="cpnews__item__content">
                                    <h3 class="cpnews__item__title">${newsitem.title}</h3>
                                    <p class="cpnews__item__intro">${newsitem.intro}</p>
                                </div>
                            </a>
                        </div>`);
                }

                i++;
            }

        })


        if (i > 0) {

            $("#news_container").html(`
             <div class="row">
                    <div class="col">
                        <h2 class="cpcontent__title">Nyheter</h2>
                    </div>
                </div>
                <div class="row row-eq-height" id="news">
                </div>
                <div class="row">
                    <div class="col text-right" id="morenews">
                    </div>
                </div>`);
        }

        if (i >= max) {
            $("#morenews").html(`
                        <a href="/${site}/nyheter" class="cpcontent__more">Se flere aktueltsaker<span class="budlight-arrow-right"></span></a>`)
        }
        news = news.join("");

        $("#news").html(news);

    });



    let meetcalender = "";

    // if (cat.categoryId === "20f06b92-b30c-49ed-b6ba-6f240b54eb5c") {

        let meetplaecholder = [{
            link: "/",
            date: "2021-01-29T16:03:08.5966806",
            title: "Tittel på kalenderhendelse",
            place: "Stedsnavn"
        }, {
            link: "/",
            date: "2021-01-29T16:03:08.5966806",
            title: "Tittel på kalenderhendelse",
            place: "Stedsnavn"
        }, {
            link: "/",
            date: "2021-01-29T16:03:08.5966806",
            title: "Tittel på kalenderhendelse",
            place: "Stedsnavn"
        }, {
            link: "/",
            date: "2021-01-29T16:03:08.5966806",
            title: "Tittel på kalenderhendelse",
            place: "Stedsnavn"
        }];



        let meetc = [];


        let meeti = 0;
        let meetmax = 3


        meetplaecholder.forEach(calitem => {
            let dj = dayjs(calitem.date)

            let day = dj.format('DD');
            let month = dj.format("MMM");
            let time = dj.format("HH:mm");

            if (meeti < meetmax) {
                meetc.push(`

                <div class="col-lg-4 mb-3">
                    <div class="cpcal">
                        <div class="row row-eq-height">
                            <div class="col-3">
                                <div class="cpcal__datespace h-100">
                                    <span class="cpcal__date">${day}.</span><br>
                                    <span class="cpcal__month">${month}</span>
                                </div>
                            </div>
                            <div class="col-9">
                                <div><a href="/${site}/${calitem.link}" class="cpcal__link">${calitem.title}</a></div>
                                <div class="cpcal__info">
                                    <span class="budlight-location-pin"></span> ${calitem.place}
                                </div>
                                <div class="cpcal__info">
                                    <span class="budlight-wall-clock"></span> ${time}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                `);
            }

            meeti++;
        })

        meetc = meetc.join("");

        let meetLink = "";
        if (meeti > meetmax) {
            meetLink = `<a href="/" class="cpcontent__more">Se hele kalenderen <span class="budlight-arrow-right"></span></a>`;
        }


        meetcalender = `
    
        <div class="row">
            <div class="col">
                <h2 class="cpcontent__title">Møtekalender</h2>
            </div>
        </div>
        <div class="row" id="cp__meeting__cal">
            ${meetc}
        </div>
        <div class="row mb-5">
            <div class="col text-right">
                ${meetLink}
            </div>
        </div>
    `;

    // }



    return `
    
    <div class="cpheader" id="cpheader__html">
    
        
    </div>
    <div class="cpcontent">
        <div class="container">
            
            <div class="cpnav" id="cpnav">
                <div class="row row-eq-height" id="categorylinks">
                </div>
            <div>
            <div class="cp__news" id="news_container">
               
            </div>

            <div class="cp__meetcal" id="cp__meetcal">
                ${aimMeta.showCalendar ? meetcalender : ''}
            </div>
        </div>
    </div>
    
    `;


}
